@import '/styles/utils/mixins';

.card {
  background-color: var(--background-color);
  border-radius: 20px;
  height: 100%;
}

.card_directStats {
  .card__box_content {
    @include desktop {
      padding-right: 72px;
    }
  }
}

.card_directTelegram {
  .card__box_content {
    @include desktop {
      padding-right: 65px;
    }
  }
}

.card__box_img {
  position: relative;
  padding-bottom: 79%;
}

.card__box_content {
  padding: 0 32px 40px;
  box-sizing: border-box;

  @include tablet {
    padding: 0 138px 72px 72px;
  }

  @include desktop {
    padding: 0 100px 72px 72px;
  }
}
.card__title {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.45;

  @include tablet {
    font-size: 28px;
  }
}

.card__text {
  margin-bottom: 24px;
}

.card__link {
  display: inline;
  line-height: 2.2;
  font-size: 14px;

  @include tablet {
    font-size: 16px;
  }

  @include desktop {
    &:hover {
      opacity: .8;
    }
  }
}

.card__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
