@import '/styles/utils/mixins';

.reviewAuthor {
  display: flex;
  align-items: center;

  @include tablet {
    margin: 0 -10px;
  }
}

.reviewAuthor__box {
  padding: 0 10px;

  @include mobile {
    display: flex;
    flex-direction: column;
    margin-left: -10px;
  }

  @include tablet {
    margin-left: -14px;
    padding: 0 12px;
  }

  @include desktop {
    padding: 0 12px;
    font-size: 18px;
    line-height: 25px;
  }
}

.reviewAuthor__imgWrapper {
  @include tablet {
    display: none;
  }
}

.reviewAuthor__img {
  @include mobile {
    width: 40px;
    height: 40px;
  }
}

.reviewAuthor__name {
  font-size: 14px;
  line-height: 1.38;
  font-weight: bold;
  color: var(--primary-text-color);

  @include tablet {
    font-size: 18px;
  }
}

.reviewAuthor__title {
  font-size: 12px;
  color: #a6a9b5;

  @include tablet {
    font-size: 14px;
  }
}
