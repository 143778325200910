@import '/styles/utils/mixins';

.wrapper {
  position: relative;
}

.arrows {
  @include mobile {
    position: absolute;
    bottom: calc(100% + 20px);
    right: -20px;
    z-index: 1;
    scale: 0.7;
  }

  @include tablet {
    position: absolute;
    bottom: calc(100% + 36px);
    right: -10px;
    z-index: 1;
    scale: 0.7;
  }

  @include desktop {
    position: absolute;
    bottom: calc(100% - 46px);
    z-index: 1;
    scale: 0.7;
  }
}

.arrow {
  position: relative;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background-color: #f6f8ff;

  &[disabled] {
    cursor: default;

    svg {
      opacity: 0.5;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    left: 12px;
    fill: var(--secondary-color);
  }
}

.arrow__prev {
  margin-right: 12px;

  svg {
    left: 7px;
    transform: rotate(-180deg);
  }
}
