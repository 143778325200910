@import '/styles/utils/mixins';

.advantage {
  margin-bottom: 32px;
  box-sizing: border-box;

  @include tablet {
    margin-bottom: 60px;
    padding: 0 30px;
    width: 50%;
  }

  @include desktop {
    padding: 0 40px;

    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }
  }
}

.advantage__icon-wrapper {
  margin-bottom: 24px;
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 9px 16px rgba(67, 67, 68, 0.06);
}

.advantage__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 24px;
  height: 24px;
  fill: var(--secondary-color);
}

.advantage__title {
  margin-bottom: 8px;

  @include desktop {
    flex: 1;
  }
}
