.root {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  padding-right: 25px;
  width: 180px;
  height: 56px;
  border-radius: 999px;
  background: #fff;

  &:hover {
    .currency {
      opacity: 0.8;
    }
  }

  .dropDownArrow {
    opacity: 0.2;
  }

  &:hover {
    .dropDownArrow {
      opacity: 1;

      path {
        stroke: var(--secondary-color);
      }
    }
  }
}

.currency {
  display: flex;
  align-items: center;
  gap: 12px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-text-color);

  &__icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.listWrapper {
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
}

.list {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  border-radius: 24px;
  padding: 4px 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 24px rgba(42, 43, 47, 0.07);
}

.itemButton {
  display: block;
  padding: 12px 16px;
  width: 100%;

  &:hover {
    .currency {
      opacity: 0.8;
    }
  }
}
