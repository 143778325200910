@import '/styles/utils/mixins';

.services {
  padding: 48px 0 54px 0;

  @include tablet {
    padding: 130px 0 70px 0;
  }

  @include desktop {
    padding: 150px 0 70px 0;
  }
}

.services__header {
  @include large-tablet {
    max-width: 50%;
  }

  @include desktop {
    max-width: 544px;
  }
}

.services__mainTitle {
  margin-bottom: 16px;
  max-width: 85%;

  @include large-tablet {
    max-width: none;
  }
}

.services__description {
  margin-bottom: 40px;
  color: var(--secondary-text-color);
  font-size: 14px;
  line-height: 1.71;

  @include tablet {
    font-size: 18px;
    line-height: 1.78;
  }
}

.services__list {
  @include large-tablet {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
  }
}

.services__item {
  margin-bottom: 24px;
  box-sizing: border-box;

  @include tablet {
    margin-bottom: 48px;
  }

  @include large-tablet {
    width: 50%;
    margin-bottom: 60px;
    padding: 0 30px;

    &:nth-child(even) {
      transform: translateY(-140px);
    }
  }
}
