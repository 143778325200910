@import '/styles/utils/mixins';

.analysis {
  position: relative;
  overflow: hidden;
  z-index: 1;

  @include mobile {
    padding-top: 16px;
    padding-bottom: 24px;
    overflow: visible;
    background-color: var(--background-color);
  }

  @include desktop {
    background-color: transparent;
  }
}

.analysis__content {
  position: relative;
  box-sizing: border-box;
  background-color: #ffffff;

  @include mobile {
    display: block;
    height: 250px;
    border-radius: 32px;
  }

  @include desktop {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 317px;
    margin: 0;
  }
}

.analysis__content:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  border-radius: 32px;
  background: linear-gradient(#d7d7f6, #4e74f5, #ff5b91, #f90043);
}

.analysis__content:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: -1;
  border-radius: 32px;
  background: #ffffff;
}

.analysis__box {
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 36px 16px;
  }

  @include desktop {
    align-items: flex-start;
    width: 580px;
    padding: 48px;
  }
}

.analysis__title {
  color: #2a2b2f;
  font-weight: 600;

  @include mobile {
    margin-bottom: 16px;
    font-size: 28px;
  }

  @include desktop {
    font-size: 40px;
  }
}

.analysis__text {
  color: var(--gray-500, #a6a9b5);

  @include mobile {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 24px;
  }

  @include desktop {
    font-size: 18px;
    line-height: 32px;
  }
}

.input {
  width: 100%;
}

.image {
  width: 340px;
  height: 289px;

  @include mobile {
    display: none;
  }

  @include desktop {
    display: block;
  }
}

.socialsHint {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray-500, #a6a9b5);

  @include mobile {
    text-align: center;
    padding: 16px 0px 0px 0px;
    line-height: 18px;
  }

  @include desktop {
    text-align: inherit;
    line-height: 12px;
    padding: 16px 0px 0px 48px;
  }
}
