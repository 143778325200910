@import '/styles/utils/mixins';

.cta {
  position: relative;
  overflow: hidden;
  background-color: #243160;
  z-index: 1;

  @include mobile {
    margin-top: 80px;
  }

  @include tablet {
    padding-top: 120px;
    margin-top: 0px;
    background-color: transparent;
    overflow: visible;
  }

  &:before {
    content: '';
    position: absolute;
    left: 192px;
    bottom: calc(100% - 74px);
    width: 319px;
    height: 319px;
    background-color: var(--secondary-color);
    border-radius: 100%;

    @include tablet {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 43px);
    left: 38px;
    width: 521px;
    height: 521px;
    background-color: var(--primary-color);
    border-radius: 100%;

    @include tablet {
      display: none;
    }
  }
}

.cta__content {
  position: relative;
  padding: 80px 0;
  box-sizing: border-box;

  @include tablet {
    margin: 0 calc(var(--base-desktop-wrapper-padding) * -1);
    padding: 80px;
    background-color: #243160;
    background-image: url(/images/cta-pattern.png);
    background-position-x: right;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include desktop {
    margin: 0;
    padding: 72px 88px;
    border-radius: 32px;
    background-image: url(/images/cta-pattern-1440.png);
  }

  @include big-desktop {
    padding: 72px 130px;
    background-image: url(/images/cta-pattern-big.png);
  }

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 172px);
    height: 377px;
    background: #111833;
    border-radius: 32px;
    z-index: -1;

    @include desktop {
      content: '';
    }
  }

  &:before {
    bottom: -19px;
    filter: blur(34px);
  }

  &:after {
    bottom: 8px;
    opacity: 0.7;
    filter: blur(50px);
  }
}

.cta__box {
  @include desktop {
    max-width: 692px;
  }
}

.cta__title {
  margin-bottom: 12px;
  font-size: 36px;
  line-height: 1.36;
  font-weight: 600;
  color: #ffffff;

  @include mobile {
    font-size: 40px;
  }

  @include tablet {
    margin-bottom: 24px;
  }

  @include desktop {
    font-size: 50px;
  }
}

.cta__text {
  margin-bottom: 27px;
  color: #bdc1cf;
  line-height: 1.875;

  @include tablet {
    margin-bottom: 49px;
  }
}

.cta__button {
  width: 100%;

  &_brazil {
    width: 222px !important;
  }

  @include tablet {
    width: auto;
  }
}
