@import '/styles/utils/mixins';

.advantages__content {
  @include desktop {
    display: flex;
  }
}

.advantages__header {
  margin-bottom: 40px;
  max-width: 280px;
  box-sizing: border-box;

  @include tablet {
    margin-bottom: 56px;
    max-width: 421px;
  }

  @include desktop {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    max-width: 500px;
    padding-right: 80px;
  }
}

.advantages__list {
  @include tablet {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px 20px;
  }

  @include desktop {
    padding-top: 48px;
    margin: 0 -40px;
  }
}

.advantages__title {
  margin-bottom: 8px;

  @include desktop {
    flex: 1;
  }
}

.advantages__link {
    display: none;
}

.advantages__link--desktop {
  display: none;

  @include desktop {
    display: inline-block;
  }
}
