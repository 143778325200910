@import '/styles/utils/mixins';

.reviews {
  padding: 80px 0;
  overflow: hidden;
  position: relative;

  @include tablet {
    padding: 120px 0px 0px 0px;
  }
}

.title {
  margin-bottom: 32px;
  color: #2a2b2f;

  @include tablet {
    margin-bottom: 48px;
    font-size: 32px;
  }

  @include desktop {
    margin-bottom: 48px;
  }
}
