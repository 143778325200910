.root {
  display: flex;
  align-items: center;
  gap: 12px;
}

.actions {
  display: flex;
  padding: 4px;
  min-width: fit-content;
  background: #fff;
  border-radius: 999px;
}

.button {
  width: 120px;
  min-width: unset;
  height: 48px;
}

.discount {
  position: relative;

  &__text {
    width: 60px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
    color: #02c39a;
    transform: translateX(10px) rotate(-15deg);

    @media (min-width: 400px) {
      width: unset;
      transform: rotate(-15deg);
    }
  }

  &__arrow {
    position: absolute;
    top: 29px;
    left: -83px;

    @media (min-width: 400px) {
      top: 22px;
    }
  }
}
