@import '/styles/utils/mixins';

.root {
  background: linear-gradient(
    180deg,
    var(--background-color) 151px,
    var(--background-color) 151px,
    #ffffff 151px,
    #ffffff 100%
  );

  @include tablet {
    background: linear-gradient(
      180deg,
      var(--background-color) 244px,
      var(--background-color) 244px,
      #ffffff 244px,
      #ffffff 100%
    );
  }

  @include desktop {
    padding-top: 105px;
  }

  padding-top: 48px;

  @include tablet {
    padding-top: 100px;
  }

  @include desktop {
  }

  &.withoutGradient {
    background: var(--background-color);

    @include tablet {
      background: var(--background-color);
    }
  }
}

.listWrapper {
  overflow: auto;
  padding-top: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile {
    padding-bottom: 32px;
  }
  @include tablet {
    padding-bottom: 48px;
  }
}

.list {
  display: flex;
  border-radius: 16px;
  min-width: fit-content;
  background: #fff;
  box-shadow: 0 18px 52px rgba(16, 25, 73, 0.1);
}

.item {
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
  padding: 32px 29px;
  min-width: 298px;
  color: var(--primary-text-color);

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 1px solid #c4c5c8;
    opacity: 0.3;
  }

  &__title {
    margin-bottom: 4px;
    line-height: 32px;
    font-size: 24px;
    font-weight: 600;
  }

  &__ico {
    position: absolute;
    top: 21px;
    right: 20px;
    width: 32px;
    height: 32px;
    fill: #ffb140;
  }

  &__text {
    margin-bottom: 16px;
    line-height: 24px;
    font-size: 14px;
    opacity: 0.5;
  }

  &__price {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 16px;

    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid #c4c5c8;
      opacity: 0.3;
    }
  }

  &__totalPrice {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--gray-500);

    &::after {
      content: '';
      position: absolute;
      top: 10px;
      left: 0px;
      height: 1.5px;
      width: 105%;
      background-color: var(--gray-500);
      transform: rotate(-8deg);
      border-radius: 20px;
    }
  }

  &__discount {
    display: inline-block;
    margin-left: 6px;
    border-radius: 20px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    background: var(--status-error);
    color: var(--main-white);
  }

  &__value {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: -0.4px;
    color: var(--secondary-color);
  }

  &__symbol {
    font-weight: 400;
    font-family: system-ui;
  }

  &__label {
    line-height: 27px;
    font-size: 20px;
    font-weight: 400;
    color: #4e74f5;
    opacity: 0.5;
  }

  &__button {
    margin-bottom: 24px;

    &_ghost {
      background: #edf1fe;
      color: var(--secondary-color);

      &:hover {
        color: #fff;
      }
    }
  }

  &__benefits {
    margin-bottom: 40px;
  }

  &__link {
    line-height: 20px;
    font-size: 14px;
  }
}

.benefits {
  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 13px;
    line-height: 12px;
    font-size: 14px;
  }

  &__ico {
    width: 20px;
    height: 20px;
    fill: var(--secondary-color);
  }
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 124px;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--gray-300, #e6e8ee);
  }

  &__icon {
    color: var(--main-blue, #4e74f5);

    &_disabled {
      color: var(--gray-300, #e6e8ee);
    }
  }

  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    color: var(--main-black, #2a2b2f);
    border: 1px solid var(--gray-300, #e6e8ee);

    &_active {
      border: 1px solid var(--main-blue, #4e74f5);
    }
  }
}