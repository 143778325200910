@import '/styles/utils/mixins';

.review__item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-right: 6px;
  width: 100%;
  height: 100%;
  transform: translateZ(0) !important;

  @include tablet {
    margin-right: 0;
    padding: 0 10px;
  }
}

.review__content {
  display: flex;
  box-sizing: border-box;

  @include tablet {
    padding: 0;
    background-color: transparent;
  }
}

.reviewAuthor__imgWrapper {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: block;
    width: 100px;
    height: 100px;
    margin-right: 50px;
  }

  @include desktop {
    width: 265px;
    height: 200px;
    margin-right: 100px;
  }
}

.reviewAuthor__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.review__textWrapper {
  width: 100%;

  @include desktop {
    margin-bottom: 56px;
  }
}

.review__text {
  width: 100%;
  position: relative;
  font-weight: 600;
  color: var(--primary-text-color);
  max-height: 336px;
  overflow: hidden;
  color: #2a2b2f;

  @include mobile {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 30px;
    overflow: visible;
  }

  @include tablet {
    font-size: 18px;
    line-height: 1.79;
    line-height: 28px;
    margin-bottom: 48px;
    max-height: none;
    overflow: visible;
  }

  @include large-tablet {
    margin-bottom: 40px;
  }

  @include desktop {
    max-width: 684px;
    margin-bottom: 40px;
  }

  @include big-desktop {
    max-width: 800px;
    margin-bottom: 40px;
  }
}

.review__text_expanded {
  max-height: none;
}

.review__readMore {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--secondary-color);
  text-align: right;
  background: linear-gradient(
    270deg,
    #f5f6fa 49.33%,
    rgba(245, 246, 250, 0) 124.02%
  );
  font-size: 14px;
  line-height: 28px;
}

.review__readMore_shown {
  display: block;

  @include tablet {
    display: none;
  }
}
