@import '/styles/utils/mixins';

.root {
  margin: 0 auto;
  max-width: 1360px;
  padding-left: var(--base-320-wrapper-padding);
  padding-right: var(--base-320-wrapper-padding);
  box-sizing: border-box;

  @include mobile {
    padding-left: var(--base-mobile-wrapper-padding);
    padding-right: var(--base-mobile-wrapper-padding);
  }

  @include tablet {
    padding-left: var(--base-desktop-wrapper-padding);
    padding-right: var(--base-desktop-wrapper-padding);
  }

  &_extended {
    @include big-desktop {
      max-width: 1720px;
    }
  }
}
